<template>
    <div class="hero-article">
        <ImageAsset
            v-if="image"
            class="hero-article__image"
            :class="imageMobile ? 'hero-article__image--desk' : ''"
            :image="image"
            sizes="100vw"
        />
        <ImageAsset
            v-if="imageMobile"
            class="hero-article__image hero-article__image--mob"
            :image="imageMobile"
            sizes="100vw"
        />
        <BaseSection
            :data-width="wide ? 'page' : 'content'"
            class="hero-article__section"
        >
            <div
                v-if="categories.length"
                class="hero-article__categories"
            >
                <span
                    v-for="category in categories"
                    :key="category.id"
                >
                    {{ category.title }}
                </span>
            </div>

            <slot name="pretitle" />

            <h1 class="hero-default__title">{{ title }}</h1>

            <div
                v-if="authors?.length > 0"
                class="hero-article__authors"
            >
                <AuthorChip
                    v-for="author in authors"
                    :key="author.title"
                    class="hero-article__author"
                    :name="author.title"
                    :role="author.role"
                    :image="author.image?.[0]"
                    :uri="author.uri"
                    :external-url="author.externalUrl"
                />
            </div>

            <!-- eslint-disable vue/no-v-html -->
            <div
                v-if="description"
                class="hero-default__description article"
                v-html="description"
            />
            <!-- eslint-enable vue/no-v-html -->

            <MewsWidget
                v-if="mews"
                :mews="mews"
                class="hero-default__mews"
            />
        </BaseSection>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        categories: {
            type: Array,
            default() {
                return [];
            }
        },
        image: {
            type: Object,
            default() {
                return {};
            }
        },
        imageMobile: {
            type: Object,
            default() {
                return {};
            }
        },
        wide: {
            type: Boolean,
            default: true
        },
        mews: {
            type: Object,
            default: null
        },
        authors: {
            type: Array,
            default() {
                return [];
            }
        }
    }
};
</script>

<style lang="less" src="./Hero.less"></style>
