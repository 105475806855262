<template>
    <button
        class="mews-widget button--primary distributor-open"
    >
        <BaseIcon icon="calendar" />
        {{ mews.btnText ?? 'Book your stay' }}
    </button>
</template>

<script setup>
const props = defineProps({
    mews: {
        type: Object,
        required: true,
        default: () => { return null; }
    }
});

useHead({
    script: [
        {
            src: 'https://api.mews.com/distributor/distributor.min.js',
            type: 'text/javascript',
            onload: () => { initializeMews(); },
        }
    ]
});

const initializeMews = () => {
    if (props.mews) {
        Mews.Distributor({
            configurationIds: [
                props.mews.mewsId
            ],
            openElements: '.distributor-open'
        });
    }
};
</script>

<style src="./MewsWidget.less" lang="less"></style>
